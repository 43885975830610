<template>
    <div>
        <div class="card-toolbar mb-5">
            <router-link v-if="$can('closed_periods.create')" to="/accounting/closed-periods/create"  class="btn btn-primary font-weight-bolder"><v-icon>mdi-plus</v-icon> {{ $t('closed_periods.new_closed_period') }}</router-link>
            <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5" >
                <span><i class="la la-filter"></i> <span>{{$t('advanced_filter')}}</span></span>
            </button>
        </div>

        <div class="card card-custom mb-5" v-if="showAdvancedSearch">
            <div class="card-body">
                <div class="m-form m-form--fit m--margin-bottom-20">
                    <div class="row">

                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="to_date">{{$t('closed_periods.to_date')}}</label>
                            <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
                        </div>
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="f_status">
                                {{$t('Added_By')}}
                            </label>
                            <!-- <select name="" id="user_id" v-model="filters.user_id" type="text" class="custom-select">
                                <option value="">{{$t('all')}}</option>
                                <option v-for="(row, index) in users" :value="row.id" :key="index">{{ row.name }}</option>
                            </select> -->
                            <multiselect v-model="user"
                                :placeholder="$t('Added_By')"
                                label="name"
                                track-by="id"
                                :options="users"
                                :multiple="false"
                                :taggable="false"
                                :show-labels="false"
                                :show-no-options="false"
                                :show-no-results="false"
                                @search-change="getUsers($event)">
                            </multiselect>
                        </div>

                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="f_status">
                                {{$t('status')}}
                            </label>
                            <select name="" id="f_status" v-model="filters.status" type="text" class="custom-select">
                                <option value="">{{$t('all')}}</option>
                                <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.title }}</option>
                            </select>
                        </div>
                        <div class="form-group d-inline-flex col-md-6 mt-6 mb-0">
                            <b-button class="mt-auto mr-2" variant="primary" @click="doFilter"><i class="fas fa-search"></i> {{$t('search')}}</b-button>
                            <b-button class="mt-auto mr-2" variant="danger" @click="resetFilter"><i class="fas fa-trash-restore"></i> {{$t('reset_search')}}</b-button>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--begin::User-->
        <div class="card card-custom">
            <div class="card-body">
                <div>
                    <div class="row justify-content-end p-4">
                        <custom-export-data v-if="$can('closed_periods.export_data')" :data-list="dataList" :fields="json_fields" :file-name="$t('MENU.closed_periods')"></custom-export-data>
                    </div>
                </div>

                <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">

                    <template slot="status" slot-scope="props">
                        <button v-if="$can('closed_periods.change_status')" @click="changeStatus(props.row.id, props.row.status)" class="btn btn-secondary m-btn m-btn--icon w-auto">
                            {{$t('change_status')}} ({{ props.row.status_name }})
                        </button>
                        <button v-else class="btn btn-secondary m-btn m-btn--icon w-auto">
                            {{$t('change_status')}} ({{ props.row.status_name }})
                        </button>
                    </template>
                    <template slot="actions" slot-scope="props">
                        <v-icon small class="mr-2 text-info" v-b-tooltip.hover :title="$t('edit')" color="blue darken-2" v-if="$can('closed_periods.update')" @click="editItem(props.row)">mdi-pencil</v-icon>
                        <v-icon small class="text-danger" v-b-tooltip.hover :title="$t('delete')" color="red darken-2" v-if="$can('closed_periods.delete')" @click="deleteItem(props.row)">mdi-delete</v-icon>
                        <v-btn v-b-tooltip.hover :title="$t('view')" icon color="pink" :to="`/accounting/closed-periods/view/${props.row.id}`">
                            <v-icon small class="mr-2 text-info">mdi-eye</v-icon>
                        </v-btn>
                    </template>
                </v-server-table>


                <!--end: Datatable-->
            </div>
        </div>
        <!--end::User-->
        <b-modal ref="status-modal" size="md" hide-footer :title="$t('change_status')">
        <change-status-form @hide-modal="hideModal()"
                :status-list="status_list"
                :url="routeChangeStatus"
                :current-id="innerId"
                :current-status="statusId"
                @handling-data="getDataAfterChangeStatus">
        </change-status-form>
    </b-modal>
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";
    import ChangeStatusFrom from "@/view/content/forms/ChangeStatusForm";


    export default {
        name: "index-closed-periods",
        components: {'change-status-form': ChangeStatusFrom,},
        data() {
            return {
                mainRoute: 'accounting/closed-periods',
                subMainRoute: 'accounting/closed-period',
                mainRouteDependency: 'base/dependency',
                routeChangeStatus : 'accounting/closed-period/change-status',
                showAdvancedSearch: false,
                dataList: [],
                filters: {
                    parent_category: null,
                    name: '',
                    from_date: '',
                    to_date: '',
                    status: '',
                    user_id: '',
                },

                status_list :[],
                users: [],
                user : null,
                innerId: null,
                statusId: null,

                columns: ['to_date', 'added_by', 'created_at', 'status', 'actions'],
            }
        },
        watch: {
            user: function (val) {
                if (val) {
                    this.filters.user_id = val.id;
                } else {
                    this.filters.user_id = null;
                }
            },
        },
        computed: {
            json_fields: function () {
                let fields = {};
                fields[this.$t('closed_periods.to_date')] = 'to_date';
                fields[this.$t('closed_periods.added_by')] = 'added_by';
                fields[this.$t('closed_periods.created_at')] = 'created_at';

                return fields;
            },
            options: function () {
                let that = this;
                return {
                    texts: {
                        loadingError: that.$t('Something_went_wrong'),
                        filter: "",
                        limit: that.$t('records'),
                        filterBy: that.$t('Filter') + ' {column}',
                        count: ' ',
                        filterPlaceholder: that.$t('Search_query'),
                        loading: that.$t('Loading') + "...",
                    },
                    headings: {
                        to_date: that.$t('closed_periods.to_date'),
                        added_by: that.$t('closed_periods.added_by'),
                        created_at: that.$t('closed_periods.created_at'),
                        status: that.$t('status'),
                        actions: that.$t('actions'),

                    },
                    sortable: ['name'],
                    filterByColumn: false,
                    filterable: false,
                    customFilters: [{
                        name: 'alphabet',

                    }],
                    orderBy: {'column': 'id'},

                    alwaysShowPerPageSelect: true,
                    perPage: 10,
                    pagination: {chunk: 5, dropdown: false},
                    skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
                    sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
                    perPageValues: [5, 10, 15, 25],

                    requestFunction(data) {
                        let _params = {
                            ascending: 0,
                            byColumn: data.byColumn,
                            limit: data.limit,
                            orderBy: data.orderBy,
                            page: data.page,
                            filter: data.query,
                            ...that.filters,
                        }
                        return ApiService.query(that.mainRoute, {..._params});

                    },
                    responseAdapter(resp) {
                        that.dataList = resp.data.data.data;
                        return {
                            data: resp.data.data.data,
                            count: resp.data.data.total,
                        }
                    },

                }

            },
        },
        mounted() {
            // let that = this;
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.closed_periods")}]);
            // this.getUsers();
            this.getStatusList();
        },
        methods: {
            getStatusList() {
                ApiService.get(this.mainRouteDependency + "/status_open").then((response) => {
                    this.status_list = response.data.data;
                });
            },
            getFetch() {
                this.$refs.table.refresh();
            },
            openAdvancedSearch() {
                this.showAdvancedSearch = !this.showAdvancedSearch;
            },
            doFilter() {
                this.$refs.table.refresh();
            },
            resetFilter() {
                this.filters.from_date = '';
                this.filters.to_date = '';
                this.filters.status = '';
                this.filters.user_id = '';
                this.user = null;

                this.$refs.table.refresh();
            },
            actionDelete(item) {
                ApiService.delete(this.mainRoute + "/" + item.id).then((response) => {
                    this.getFetch();
                    this.$successAlert(response.data.message)
                }).catch((error) => {
                    this.$errorAlert(error);
                })
            },
            editItem(item) {
                this.$router.push({name: 'closed-periods.edit', params: {id: item.id}});
            },
            deleteItem(item) {
                this.$confirmAlert('', this.actionDelete, item);
            },

            getUsers(filter) {
                if(filter && filter.length >= 3){
                    ApiService.get(`${this.mainRouteDependency}/users`,{params:{filter: filter}}).then((response) => {
                        this.users = response.data.data;
                    });
                }else{
                    this.users = [];
                }
            },

            changeStatus(id, status) {
                this.innerId = id;
                this.statusId = status;
                this.showModal();
            },
            showModal() {
                this.$refs['status-modal'].show()
            },
            hideModal() {
                this.$refs['status-modal'].hide();
            },
            getDataAfterChangeStatus() {
                this.innerId = null;
                this.statusId = null;
                this.doFilter();
            },

        },
    };
</script>
